.joinServer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    background: none;

    .avatarTitle {
        margin-top: -8px;
        font-family: 'Noto Sans SC';
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        height: 28px;
        color: #FFFFFF;
    }

    .info {
        display: flex;

        .welcome {
            display: flex;
            flex-direction: column;
            margin-left: 16px;
            font-family: 'Noto Sans SC';
            font-size: 14px;

            .title {
                line-height: 28px;
                color: #9e9e9e;
            }

            .name {
                font-size: 16px;
                font-weight: 700;
                line-height: 28px;
                color: #fff;
            }
        }
    }

    .desc {
        margin-top: 16px;
        line-height: 24px;
        font-family: 'Noto Sans SC';
        font-size: 14px;
        color: rgba(255, 255, 255, 0.87);
    }

    .buttons {
        display: flex;
        position: absolute;
        bottom: 0;
        right: 0;

        .confirm {
            margin-left: 16px;
        }
    }
}