html,
body {
  height: 100%;
  width: 100%;
  min-width: 1280px;
  overflow-x: scroll;
  overflow-y: hidden;
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;
}

.App {
  height: 100%;
  width: 100%;
  text-align: left;
  user-select: none;
}

.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.ant-message-notice .ant-message-notice-content {
  background: #181818 !important;
  height: 52px;
  line-height: 52px;
  padding: 0 28px;
  color: #fff;
  font-family: "PingFang SC";
  font-size: 14px;
  border-radius: 12px;

  .ant-message-warning,
  .anticon {
    color: #e6e6e6;
  }

  .anticon {
    padding-block: 2px;
  }

  a {
    color: #16d265;
  }
}

span.ant-input-suffix {
  font-size: 20px;
}

.ant-input-clear-icon {
  display: flex;
  align-items: center;
}

.ant-input-password-icon.anticon {
  color: #979797;
}

.ant-input-password-icon.anticon:hover {
  color: #979797;
}

.ant-menu.ant-menu-dark {
  background: unset;
}

.ant-menu-horizontal {
  border: none;
}

//一级分组菜单
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub,
.ant-dropdown-menu{
  width: 238px;
  background: #292929;
  border-radius: 8px;
  color: #ccc;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  padding: 8px;
  border: 1px solid rgba(77, 77, 77, 0.6);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 24px 36px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(16px);

  .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-vertical {
    padding: 0;
    background: none;
    border: none;
    box-shadow: none;
    backdrop-filter: none;
    .ant-dropdown-menu-submenu-expand-icon{
      display: none;
    }
  }
}
.ant-menu-submenu-popup .ant-menu-vertical>.ant-menu-item {
  height: auto;
  overflow: auto;
  padding: 0;
  margin: 0;
  width: 100%;
  // padding-left: 4px;
}
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:not(:first-child) {
  margin-top: 8px;
}

.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background: #333;
}
//二级分组菜单
.subMenuStyle {
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  backdrop-filter: none;
  width: 200px!important;
 
  .ant-dropdown-menu,.ant-menu.ant-menu-sub.ant-menu-vertical {
    padding: 8px;
    width: 200px;
    border-radius: 8px;
    background: #292929;
    max-height: 100vh;
    overflow-y: scroll;
    border: 1px solid rgba(77, 77, 77, 0.6);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 24px 36px rgba(0, 0, 0, 0.24);
    .ant-menu-item{
      padding-left: 0;
    }

    .ant-dropdown-menu-item {
      padding: 0;
      &:hover{
        background: transparent!important;
      }
    }
    .ant-menu-item {
      &:hover{
        background: transparent!important;
      }
    }
  }
  .ant-dropdown-menu-submenu-expand-icon {
    display: none
  }
}
.ant-dropdown-menu-title-content{
  width: 100%;
}

.ant-menu-dark .ant-menu-item {
  border-radius: 8px;
  height: auto;
  overflow: auto;
  color: #c4c5c6;
}

.ant-menu-vertical>.ant-menu-item+.ant-menu-item,
.ant-menu-vertical>.ant-menu-item+.ant-menu-submenu,
.ant-menu-vertical>.ant-menu-submenu+.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu+.ant-menu-submenu {
  margin-top: 8px;
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
  background: #333;
}

.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
  padding: 0;
  margin: 0;
  margin-block: 0;
  width: 100%;
}

.ant-menu-item .anticon+span {
  margin-left: 8px;
}

.ant-menu-vertical .ant-menu-item {
  margin-bottom: 0;
  margin-top: 0;
}

.ant-menu-dark .ant-menu-item:hover {
  background: #ccc;
  border-radius: 8px;
  color: #fefefe;
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item,
.ant-menu-dark.ant-menu-horizontal>.ant-menu-submenu {
  padding: 0;
}

.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}

.customForm {
  .ant-modal-close-x {
    height: 54px;
    width: 46px;
  }

  .ant-modal-body {
    padding: 32px 15px;
  }

  .ant-modal-footer {
    padding: 24px;
  }

  .ant-form-item-control {
    background: #333;
    border-radius: 16px;
    padding: 6px 14px;
  }

  .ant-input-show-count-suffix {
    color: #929497;
  }

  .ant-input,
  .ant-input-affix-wrapper {
    padding: 0;
    background: unset;
    outline: none;
    box-shadow: none;
    color: #fff;
    border: none;
    resize: none;
    font-size: 16px;

    &::placeholder {
      color: #929497;
    }
  }

  .ant-input-suffix {
    font-size: 16px;
  }

  .ant-form-item-label>label {
    color: #c7c7c7;
    font-size: 12px;
  }

  .ant-input-textarea-show-count::after {
    margin-right: 8px;
    margin-top: -21px;
    color: #929497;
    font-size: 16px;
  }
}

.customFormItem {
  .ant-form-item-control {
    background: none;
    padding: 0;
  }

  .privateWrap {
    min-height: unset;
  }
}

.customForm .formRadioGroup .ant-form-item-control {
  background: none;
}

.ant-modal-root {
  .ant-modal-title {
    color: #fff;
    width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    line-height: 22px;
  }

  .ant-modal-header {
    padding-right: 40px;
    background: #3a3a3a;
    border: none;
    padding: 16px 24px;
    margin-bottom: 0;
  }

  .ant-modal-content {
    background: #292929;
    border-radius: 16px;
    overflow: hidden;
    border: 1px solid #4d4d4d;
    box-shadow: 0px 24px 36px rgba(0, 0, 0, 0.12),
      8px 0px 24px rgba(0, 0, 0, 0.08);
    padding: 0;
  }

  .ant-modal-footer {
    border: none;
    padding: 24px;
  }

  .ant-modal-close-x {
    display: flex;
    align-items: center;
  }
}

.userInfoModal {
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close-x {
    width: 46px;
  }
}

.logoutModal {
  padding-bottom: 0;
  .ant-modal-header {
    background: #3A3A3A;
    height: 60px;
  }
  
  .ant-modal-content  .ant-modal-close{
    height: 60px;
  }
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close-x {
    width: 46px;
  }
}

.img-crop-modal {
  .ant-modal-content {
    border: none;

    .ant-modal-close .ant-modal-close-x {
      display: block;
      color: #c7c7c7;
      font-size: 18px;
      width: 54px;
    }

    .ant-modal-body {
      padding: 24px;
    }

    .ant-modal-close {

      &:hover,
      &:active {
        background: none;
      }
    }
  }

  .ant-btn {
    height: auto;
  }

  .ant-btn-primary {
    background: transparent !important;
    box-shadow: none;
    border: none;
    padding: 0;
    text-shadow: none;
    letter-spacing: 0;
  }

  .ant-btn-two-chinese-chars>* {
    letter-spacing: 0;
  }

  .ant-btn-default {
    background: unset;
    padding: 0;
    color: #fff;
    border: none;
    font-size: 16px;
    font-weight: 500;
    text-shadow: none;
    letter-spacing: 0;
  }

  .ant-modal-footer {
    padding: 24px;
  }

  .img-crop-control {
    color: #fff;
  }
}

.circleBtn {
  background: #27ae60;

  &:hover {
    background: #2dc76e;
  }

  &:focus {
    background: #2dc76e;
  }

  &:active {
    background: #219452;
  }

  &.disable {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background: #27ae60;
    }

    &:active {
      background: #27ae60;
    }
  }
}

.circleBtnGray {
  background: #3f3f3f;

  &:hover {
    background: #595959;
  }

  &:focus {
    background: #595959;
  }

  &:active {
    background: #262626;
  }

  &.disable {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background: #3f3f3f;
    }

    &:active {
      background: #3f3f3f;
    }
  }
}

.circleBtn152 {
  width: 152px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 48px;
  font-family: "Noto Sans SC";
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}

.circleBtn106 {
  width: 106px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 48px;
  font-family: "Noto Sans SC";
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}

.circleBtn66 {
  width: 66px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 48px;
  font-family: "Noto Sans SC";
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
}

.confirmModalWrap {
  .ant-btn-primary {
    border: none;
    border-radius: 24px;
    font-size: 16px;
    width: 110px;
    text-align: center;
    margin-left: 14px !important;
    text-shadow: none;
  }

  .ant-modal-body {
    .ant-modal-confirm-title {
      padding-left: 24px;
      display: flex;
      align-items: center;
      height: 60px;
      background: #393939;
    }

    .ant-modal-confirm-content {
      min-height: 64px;
      margin: 26px 24px 0 22px;
      margin-block-start: 26px !important;
      box-sizing: border-box;
    }

    .ant-modal-confirm-btns {
      margin: 24px;
    }
  }

  .ant-btn-default {
    border: none;
    border-radius: 24px;
    color: rgb(255, 255, 255);
    font-size: 16px;
    width: 110px;
    text-align: center;
    text-shadow: none;
  }

  .ant-modal-close {
    display: flex;
    height: 60px !important;
    align-items: center;
    margin-right: 14px;

    .ant-modal-close-x {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
    }

  }
}

::-webkit-scrollbar {
  display: none;
  //width: 22px;
}


/* 设置滚动条的背景颜色 */
::-webkit-scrollbar-track {
  background-color: #181818
}

.ant-spin-dot-item {
  background-color: #ccc;
}

.ant-modal-confirm {
  .ant-modal-confirm-btns {
    margin-top: 42px;
  }
}

.circleDropDown {
  // border: 1px solid #444;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 24px 36px rgba(0, 0, 0, 0.24);
  border-radius: 12px;
  box-sizing: border-box;

  .ant-dropdown-menu {
    padding: 8px;
    width: 238px;
    background: #292929;
    border-radius: 8px;

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title,
    .ant-dropdown-menu-item-group-list>.ant-dropdown-menu-item {
      padding: 0;
      width: 100%;
      height: 38px;
      background: none;
      border-radius: 8px;

      &:hover {
        background: #333;
      }

      .ant-dropdown-menu-title-content {
        display: flex;
        // align-items: center;
        height: 100%;
        width: 100%;
      }
    }

    .ant-dropdown-menu-item:not(:first-child),
    .ant-dropdown-menu-submenu:not(:first-child) {
      margin-top: 8px;
    }
  }
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-group-list {
  margin: 8px 0 0 0;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-group-title {
  height: 38px;
  line-height: 28px !important;
  background: #333333;
  border-radius: 8px;
  font-family: 'Noto Sans SC';
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  width: 222px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.circleDropItem {
  display: flex;
  align-items: center;
  padding-left: 4px;
  height: 38px;
  width: 100%;

  .circleDropMenuIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    color: rgba(255, 255, 255, 0.74);
  }

  .circleDropMenuOp {
    margin-left: 8px;
    line-height: 38px;
    font-family: "Noto Sans SC";
    font-weight: 500;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.74);
  }

  &:hover {
    .circleDropMenuIcon {
      color: #fff;
    }

    .circleDropMenuOp {
      color: #fff;
    }
  }
}


.ant-empty {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.toolTip {
  .ant-tooltip-content {
    --antd-arrow-background-color: #3A3A3A;

    .ant-tooltip-inner {
      padding: 8px;
      background: #3A3A3A;
      font-family: 'Noto Sans SC';
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      border-radius: 6px;
      text-align: center;
      color: rgba(255, 255, 255, 0.9);
      box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.12), -2px 0px 8px rgba(0, 0, 0, 0.08);
    }

    .ant-tooltip-arrow {
      .ant-tooltip-arrow-content {
        background-color: #3A3A3A !important;
      }
    }
  }

  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: #3A3A3A;
  }
}

.toolTip2 {
  --antd-arrow-background-color: #181818;

  .ant-tooltip-inner {
    padding: 8px;
    background: #181818;
    font-family: 'Noto Sans SC';
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    border-radius: 6px;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
    filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.2));
    white-space: nowrap;
  }

  .ant-tooltip-arrow {
    .ant-tooltip-arrow-content {
      background-color: #181818 !important;
    }
  }

  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: #181818;
    ;
  }
}

.ant-menu-horizontal {
  line-height: normal;
}

.ant-popover .ant-popover-inner {
  padding: none;
  background: transparent;
}

.ant-image-mask-info {
  display: none;
}

.ant-modal-mask {
  backdrop-filter: blur(5px);
}

.memberItem {
  .opBg {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    cursor: pointer;
    background: #0F0F0F;

    .opIcon {
      font-size: 22px;
      color: #474747;
    }
  }

  &:hover {
    .opBg {
      background: #181818;

      .opIcon {
        font-size: 22px;
        color: #fff;
      }
    }

  }
}

.messageOperationIcon {
  color: #c9c9c9;

  &:hover {
    color: #fff;
  }
}

//滑块相关样式
.ant-slider .ant-slider-rail {
  background: #fff !important;
}

//所有Modal的关闭按钮
.ant-modal .ant-modal-close {
  top: 0;
  right: 0;
  height: 54px;
  width: auto;

  &:hover,
  &:active {
    background-color: transparent;
  }
}

//取消、确认按钮样式
.ant-btn-default:not(:disabled) {
  &:hover {
    color: #fff;
    border-color: #595959;
  }

  &:active {
    color: #fff;
    border-color: #262626;
  }

}

.ant-btn-primary:not(:disabled) {
  &:hover {
    color: #fff;
    background: #2dc76e;
  }

  &:active {
    color: #fff;
    background: #219452;
  }
}