.avatarCon {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .dot {
        position: absolute;
        bottom: -2px;
        right: -2px;
        display: inline-block;
        height: 14px;
        width: 14px;
        border: 2px solid #3d3d3d;
        border-radius: 100%;
        box-sizing: border-box;

    }

    .bigDot {
        height: 24px;
        width: 24px;
    }

    .online {
        background: #14FF72;
    }

    .offline {
        background: #C4C4C4;
    }
}