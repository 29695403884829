.con{
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.65);
    .tips{
        position: absolute;
        top: 20px;
        padding: 8px;
        width: 580px;
        background: #292929;
        border-radius: 8px;
        line-height: 24px;
        font-family: 'Noto Sans SC';
        font-size: 14px;
        color: #fff;
    }
}